import {CopyToClipboardButton} from '@github-ui/copy-to-clipboard/Button'
import styles from './CodeBlock.module.css'
import {clsx} from 'clsx'
import {getLanguageInfo} from '@github-ui/copilot-chat/utils/language-info'
import type {ReactBlockAttributes} from '../../extension'
import hljs from 'highlight.js'
import {useContext, useMemo} from 'react'
import {SafeHTMLDiv} from '@github-ui/safe-html'
import {LanguageDot} from './LanguageDot'
import {ExtensionContext} from '../ExtensionContext'

import streamingCursorStyles from '../streaming-cursor/streaming-cursor.module.css'
import {sendEvent} from '@github-ui/hydro-analytics'

export const languageAttribute = 'data-codeblock-lang'
export const isCompleteAttribute = 'data-codeblock-is-complete'

export const codeBlockAttributes = [languageAttribute, isCompleteAttribute]

export function CodeBlock({
  [languageAttribute]: language = '',
  [isCompleteAttribute]: isCompleteStr = '',
  children,
}: ReactBlockAttributes) {
  const {color, name: displayName} = getLanguageInfo(language)

  const trimmedCode = children.trim()

  const extensionContext = useContext(ExtensionContext)
  const isStreaming = !isCompleteStr && extensionContext.isStreaming

  const highlighted = useMemo(() => {
    if (!language) return null

    try {
      const highlightedCode = hljs.highlight(trimmedCode, {language}).value
      return isStreaming
        ? `${highlightedCode} <span class="${streamingCursorStyles.streamingCursor}"></span>`
        : highlightedCode
    } catch {
      // unrecognized language ID; just render plain text
      return null
    }
  }, [trimmedCode, language, isStreaming])

  const multiline = trimmedCode.includes('\n')

  const code = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <pre className={styles.code} tabIndex={0}>
      <code>
        {highlighted ? (
          <SafeHTMLDiv unverifiedHTML={highlighted} />
        ) : (
          <>
            {trimmedCode}
            {isStreaming && <span className={streamingCursorStyles.streamingCursor} />}
          </>
        )}
      </code>
    </pre>
  )

  const copyButton = (
    <CopyToClipboardButton
      textToCopy={trimmedCode}
      ariaLabel="Copy code"
      size="small"
      className={styles.copyButton}
      onCopy={() => sendEvent('dotcom_chat.activate', {target: 'CODE_BLOCK_COPY', mode: extensionContext.chatMode})}
    />
  )

  return multiline ? (
    <figure className={clsx(styles.container, styles.multiline)}>
      <figcaption className={styles.headerContainer}>
        <div className={styles.header}>
          <LanguageDot color={color} />
          <span className={styles.languageName}>{displayName || 'Code'}</span>
          {
            // hide button while streaming to avoid flickering tooltip / copying partial code
            !extensionContext.isStreaming && copyButton
          }
        </div>
      </figcaption>

      <div className={styles.codeContainer}>{code}</div>
    </figure>
  ) : (
    <div className={clsx(styles.container, styles.singleLine)}>
      {code}
      {copyButton}
    </div>
  )
}

try{ CodeBlock.displayName ||= 'CodeBlock' } catch {}