import type {CopilotMarkdownExtension} from '../../extension'
import styles from './streaming-cursor.module.css'

const char = '▋'

/**
 * Renders a blinking cursor after the markdown. Note that this doesn't work in React blocks since they replace
 * the entire HTML for the block. React blocks should handle their own streaming state by checking if they are
 * incomplete and if the message is streaming.
 */
export default function streamingCursorExtension(): CopilotMarkdownExtension {
  return {
    marked: [
      {
        hooks: {
          // Inserting the caret before rendering ensures that we keep it inside the current tag (ie, at the end of a header)
          preprocess: md => `${md} ${char}`,
          // eslint-disable-next-line github/unescaped-html-literal
          postprocess: html => html?.replaceAll('▋', `<span class="${styles.streamingCursor}"></span>`),
        },
      },
    ],
    sanitizer: {
      allowedClassNames: Object.values(styles),
    },
  }
}
