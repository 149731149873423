import type {CopilotMarkdownExtension} from '../../extension'
import {allowAttributesHook, attributesSelector} from '../../utils'
import {CodeBlock, codeBlockAttributes, isCompleteAttribute, languageAttribute} from './CodeBlock'
import styles from './CodeBlock.module.css'

/** A completed code block is one that has a closing delimeter. Incomplete blocks are still streaming. */
const completeCodeBlockRegex = /\n(?:`{3,}|~{3,})\s*$/

export function improvedCodeBlocksExtension(): CopilotMarkdownExtension {
  return {
    marked: [
      {
        extensions: [
          {
            name: 'code',
            renderer(token) {
              if (token.type !== 'code') return false

              const lang = token.lang.split(' ')[0] ?? ''
              const isComplete = completeCodeBlockRegex.test(token.raw)

              const element = document.createElement('div')
              element.setAttribute(languageAttribute, lang)
              element.textContent = token.text
              element.classList.add(styles.blockContainer)
              element.setAttribute(isCompleteAttribute, isComplete ? 'true' : '')

              return element.outerHTML
            },
          },
        ],
      },
    ],
    sanitizer: {
      attributeHook: allowAttributesHook(codeBlockAttributes),
      allowedClassNames: [styles.blockContainer],
    },
    react: {
      selector: attributesSelector(codeBlockAttributes),
      Component: CodeBlock,
    },
  }
}
